import React from 'react';
import './Services.css';
import { BsCode, BsGlobe } from 'react-icons/bs';
import { FiShoppingCart } from 'react-icons/fi';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { FaArrowsAltH, FaPaintBrush, FaExchangeAlt } from 'react-icons/fa';
import { MdOutlineShoppingBag } from "react-icons/md";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";

const Services = () => {
    const { t } = useTranslation();

    return (
        <div className='ServicesContainer'>
            <h2 className='ServicesHeader'>{t('SERVICES')}</h2>
                <div className='ServiceCard'>
                    <BsCode className='service-icon-1' size={50} />
                    <h3 className='serviceCard-header'>{t('SERVICES1')}</h3>
                    <p className='serviceCard-description'>{t('SERVICES2')}</p>
                </div>
                <div className='ServiceCard'>
                    <div className='service-icons'>
                        <MdOutlineShoppingBag className='service-icon-2' size={50} />
                    </div>
                    <h3 className='serviceCard-header'>{t('SERVICES3')}</h3>
                    <p className='serviceCard-description'>{t('SERVICES4')}</p>
                </div>
                <div className='ServiceCard'>
                    <BsGlobe className='service-icon-3' size={50} />
                    <h3 className='serviceCard-header'>{t('SERVICES5')}</h3>
                    <p className='serviceCard-description'>{t('SERVICES6')}</p>
                </div>
                <div className='ServiceCard'>
                    <div className='service-icons'>
                        <HiOutlineLightBulb className='service-icon-4' size={50} />
                    </div>
                    <h3 className='serviceCard-header'>{t('SERVICES7')}</h3>
                    <p className='serviceCard-description'>{t('SERVICES8')}</p>
                </div>
                <div className='ServiceCard'>
                    <FaExchangeAlt className='service-icon-5' size={50} />
                    <h3 className='serviceCard-header'>{t('SERVICES9')}</h3>
                    <p className='serviceCard-description'>{t('SERVICES10')}</p>
                </div>
                <div className='ServiceCard'>
                    <FaPaintBrush className='service-icon-6' size={50} />
                    <h3 className='serviceCard-header'>{t('SERVICES11')}</h3>
                    <p className='serviceCard-description'>{t('SERVICES12')}</p>
            </div>
        </div>
    );
};

export default Services;
