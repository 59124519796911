import React, { useState, useEffect } from 'react';
import './Header.css';
import Hamburger from 'hamburger-react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import finlandFlag from './Images/Suomi3.jpg';
import ukFlag from './Images/UK.png';
import lamppu from './Images/lamppu.png';
import lamppu3 from './Images/lamppu3.png';
import keltainenLamppu from './Images/lamppukeltainen1.png';

const Header = ({ text }) => {
  const [isEnglish, setIsEnglish] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const changeLanguageHandler = () => {
    if (isEnglish) {
      i18n.changeLanguage("fi");
      setIsEnglish(!isEnglish);
    } else {
      i18n.changeLanguage("en");
      setIsEnglish(!isEnglish);
    }
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const { t } = useTranslation();

  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      let st = window.scrollY || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setIsScrolled(st !== 0);
      lastScrollTop = st <= 0 ? 0 : st;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`header ${isScrolled ? 'scrolled' : ''}`}>
      
      <div className='navbar-flag'>
      <a className='mobile-flagbutton' onClick={changeLanguageHandler}>
        {isEnglish ? (
          <img src={finlandFlag} alt="Switch to Finnish" className='fin-flag' />
        ) : (
          <img src={ukFlag} alt="Switch to English" className='eng-flag' />
        )}
      </a>
      </div>
 

      {/* Full-page menu for mobile */}
      {isOpen && (
        <div className="navbar-nav">
          <img className='header-keltainenlamppu' src={keltainenLamppu} alt="Company Logo" />
          <a href="#services" className='header-font' onClick={closeMenu}>{t('SERVICES')}</a>
          <a href="#projects" className='header-font' onClick={closeMenu}>{t('PROJECTS')}</a>
          <a href="#team" className='header-font' onClick={closeMenu}>{t('OURPEOPLE')}</a>
          <a href="#pricing" className='header-font' onClick={closeMenu}>{t('PRICING')}</a>
          <a href="#contact" className='header-font' onClick={closeMenu}>{t('CONTACTUS')}</a>

          {/* Social Media Links */}
          <div className='social-media'>
            <a href="https://www.facebook.com/profile.php?id=61551928770102" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f headersome" style={{fontSize:"42px"}}></i>
            </a>
            <a href="https://www.instagram.com/tinkerit.fi/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram headersome" style={{fontSize:"42px"}}></i>
            </a>
            <a href="https://www.linkedin.com/company/96452239/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in headersome" style={{fontSize:"42px"}}></i>
            </a>
          </div>
        </div>
      )}

      {text && <h1 className="heading">{text}</h1>}

      <div className='navbar-lamppu'>
      <a href="#" className="header-lamppu-link">
        {isScrolled ? (
          <img className="HeaderLamppuMobile" src={lamppu} alt="Company Logo" />
        ) : (
          <img className="HeaderLamppuMobile" src={lamppu3} alt="Company Logo" />
        )}
      </a>
      </div>


      <div className='navbar'>
        <label htmlFor="menu-toggle" className={`hamburger-chooser ${isScrolled ? 'hamburger-chooser-top' : ''} ${isOpen ? 'hamburger-chooser-open' : ''}`}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </label>

        {/* Desktop when scrolled */}
        {isScrolled && (
          <div className='navbar_links'>
            <a href="#services" className='header-font'>{t('SERVICES')}</a>
            <a href="#projects" className='header-font'>{t('PROJECTS')}</a>
            <a href="#team" className='header-font'>{t('OURPEOPLE')}</a>
            <a href="#pricing" className='header-font'>{t('PRICING')}</a>
            <a href="#contact" className='header-font'>{t('CONTACTUS')}</a>
            <a className='whitetext' onClick={changeLanguageHandler}>
              {isEnglish ? (
                <img src={finlandFlag} alt="Switch to Finnish" className="flag" />
              ) : (
                <img src={ukFlag} alt="Switch to English" className="flag" />
              )}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;