import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import './CookieConsent.css';
import { useTranslation } from 'react-i18next';

function CookieConsent() {
    const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowBanner(true);
    } else if (cookiesAccepted === 'true') {
      initializeAnalytics();
    }

    const handleShowBanner = () => setShowBanner(true);
    document.addEventListener('showCookieConsent', handleShowBanner);

    return () => {
      document.removeEventListener('showCookieConsent', handleShowBanner);
    };
  }, []);

  const initializeAnalytics = () => {
    ReactGA.initialize('G-NF9P8D0MLE'); // Replace with your actual TRACKING_ID
    ReactGA.send('pageview');
  };

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    initializeAnalytics();
    setShowBanner(false);
  };

  const handleRefuse = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>{t('COOKIE_CONSENT_MESSAGE')} <a style={{color: "white"}} href='./Cookies.pdf' target="_blank" rel="noopener noreferrer">{t('PRIVACY-STATEMENT')}</a></p>
      <div className="cookie-consent-buttons">
        <button onClick={handleAccept}> {t('ACCEPT')}</button>
        <button onClick={handleRefuse}> {t('DECLINE')}</button>
      </div>
    </div>
  );
}

// Expose the showCookieConsentBanner method globally
export function showCookieConsentBanner() {
  document.dispatchEvent(new Event('showCookieConsent'));
}

export default CookieConsent;
