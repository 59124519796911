import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa'; 
import './PersonCard.css';

const PersonCard = ({ id, image, hoverImage, name, info, quote, description, github, linkedin, instagram, scrollPosition, backgroundColor, smallBoxColor }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (!expanded && window.innerWidth <= 700) {
      window.scrollTo(0, scrollPosition);
    }
  };
  
  const closeExpanded = (event) => {
    event.stopPropagation();
    setExpanded(false);
  };

  return (
    <div className="person-container">
      <div className="person-box" onClick={toggleExpanded}>
        <div className="person-card" style={{ backgroundColor }}>
          <img src={image} alt={name} className={`person-image person-image-${id}`} />
          {hoverImage && <img src={hoverImage} alt={`${name} hover`} className={`person-image-new person-image-new-${id}`} />}
          <h3 className="person-name">{name}</h3>
          <p className="person-info">{info}</p>
        </div>
      </div>
      {expanded && (
        <div className="expanded-card-overlay">
         <div className="expanded-card" style={{ backgroundColor: backgroundColor }} onClick={(e) => e.stopPropagation()}>
          <div className="expanded-left">
            <div className="left-upper">
              <div className="small-box" style={{ backgroundColor: smallBoxColor }}></div>
              <div className="person-image-extended-box">
               <img src={image} alt={name} className={`person-image-expanded person-image-expanded-${id}`} />
            </div>
            </div>

            <div className="left-lower">
            
            <div className="name-info">
                <h3 className="person-name-extended">{name}</h3>
                <p className="person-info-extended">{info}</p>
              </div>
            </div>
            </div>

           <div className="expanded-right">
            <p className="person-quote">"{quote}"</p>
            <p className="person-description">{description}</p>
            <div className="social-buttons-container">
              {github && (
                <a href={github} target="_blank" rel="noopener noreferrer" className="social-button">
                  <FaGithub />
                </a>
              )}
              {instagram && (
                  <a href={github} target="_blank" rel="noopener noreferrer" className="social-button">
                    <FaInstagram />
                  </a>
                )}
              {linkedin && (
                <a href={linkedin} target="_blank" rel="noopener noreferrer" className="social-button">
                  <FaLinkedin />
                </a>
              )}
            </div>
            </div>
            <div className='expanded-mobile'>
              <div className='person-image-extended-box'>
                <div className='small-box' style={{ backgroundColor: smallBoxColor }}></div>
                <img src={image} alt={name} className={`person-image-expanded person-image-expanded-${id}`} />
              </div>
              <h3 className="person-name-extended">{name}</h3>
              <p className="person-info-extended">{info}</p> 
              <p className='person-quote'>"{quote}"</p>
              <p className="person-description">{description}</p>
              <div className="social-buttons-container">
                {github && (
                  <a href={github} target="_blank" rel="noopener noreferrer" className="social-button">
                    <FaGithub />
                  </a>
                )}
                {instagram && (
                  <a href={github} target="_blank" rel="noopener noreferrer" className="social-button">
                    <FaInstagram />
                  </a>
                )}
                {linkedin && (
                  <a href={linkedin} target="_blank" rel="noopener noreferrer" className="social-button">
                    <FaLinkedin />
                  </a>
                )}
              </div>
            </div>
            <button className="close-button" onClick={closeExpanded}>
              <MdClose />
            </button>
          </div>
          <div className="overlay" onClick={closeExpanded}></div>
        </div>
      )}
    </div>
  );
}

export default PersonCard;
