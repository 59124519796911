import React from 'react';
import './Pricing.css';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations
import logo from '../Images/lamppukeltainen1.png';
import { color } from 'framer-motion';

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className="pricing-container">
                <img
            className='pricing-logo'
            alt="logo"
            src={logo}
            width="300px"
          />
      <h2 className="pricing-header">{t('PRICING')}</h2>
      <div className="pricing-sections">
        <div className="pricing-section pricing-left">
          <div className="pricing-price">
            <span className="discounted-price">499€</span>
            <span className='sale-price'>499€ </span> 
            <span className='pricing-alv'>{t("ALV")}</span>
          </div>
          <h3 className="pricing-title">{t('PRICING1')}</h3>
          <br></br>
          <p className="pricing-description">
            <strong>{t('PRICING1-1')}</strong><br />
            <br></br>
            · {t('PRICING1-2')}<br />
            <br></br>
            · {t('PRICING1-3')}<br />
            <br></br>
            · {t('PRICING1-4')}
          </p>
          <br></br>
          <a href="#contact" className="pricing-button">{t('PRICING-BUTTON')}</a>
        </div>
        <div className="pricing-section pricing-right">
        <div className="pricing-price">
            <span className="discounted-price">899€</span>
            <span className='sale-price'>899€ </span> 
            <span className='pricing-alv'>{t("ALV")}</span>
          </div>
          <h3 className="pricing-title">{t('PRICING2')}</h3>
          <br></br>
          <p className="pricing-description">
            <strong>{t('PRICING2-1')}</strong><br />
            <br></br>
            · {t('PRICING2-2')}<br />
            <br></br>
            · {t('PRICING2-3')}<br />
            <br></br>
            · {t('PRICING2-4')}
          </p>
          <br></br>
          <a href="#contact" className="pricing-button">{t('PRICING-BUTTON')}</a>
        </div>
        <div className="pricing-section pricing-left">
        <div className="pricing-price">
            <span className="discounted-price">999€</span>
            <span className='sale-price'>999€ </span>  
            <span className='pricing-alv'>{t("ALV")}</span>
          </div>
          <h3 className="pricing-title">{t('PRICING3')}</h3>
          <br></br>
          <p className="pricing-description">
            <strong>{t('PRICING3-1')}</strong><br />
            <br></br>
            · {t('PRICING3-2')}<br />
            <br></br>
            · {t('PRICING3-3')}<br />
            <br></br>
            · {t('PRICING3-4')}
          </p>
          <br></br>
          <a href="#contact" className="pricing-button">{t('PRICING-BUTTON')}</a>
        </div>
        <div className="pricing-section pricing-right">
        <p className="pricing-price">
        <div className='pricing-lastcard-header'>{t('PRICING4')}</div>
          </p>
          <h3 className="pricing-title pricing-lastcard-title">{t('PRICING4-1')}</h3>
          <p className="pricing-description">
          <br></br>
            · {t('PRICING4-2')}<br />
            <br></br>
            · {t('PRICING4-3')}<br />
            <br></br>
            · {t('PRICING4-4')}<br />
            <br></br>
            · {t('PRICING4-5')}<br />
          </p>
          <br></br>
          <a href="#contact" className="pricing-button">{t('PRICING-BUTTON-2')}</a>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
