import React from 'react';
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
import i18n from "./components/i18n";

import { Footer } from './Footer';
import Services from './components/Services';
import PersonCard from './components/PersonCard';
import Projects from './components/Projects';
import Technologies from './components/Technologies';
import Pricing from './components/Pricing';
import ContactUs from './components/ContactUs';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import * as wateranimation from './components/lotties/wateranimation.json'
import { Player } from '@lottiefiles/react-lottie-player';
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";

import VideoBackground from './components/VideoBackground';
import logo from './Images/header-logo.png';
import lamppu2 from './Images/lamppu2.png';
import lamppu from './Images/lamppu.png'
import keltainenlamppu from './Images/lamppukeltainen1.png';

import Header from './Header';


// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';


const backgroundImageUrl = require('./Images/header.jpg');

const handleClick = () => {
  window.location.href = "mailto:info@tinkerit.fi" 
};


const boxVariant = {
  visible: { opacity: 1, scale: 1 },
  hidden: { opacity: 0, scale: 0 },
}

const Home = () => {
  
  const { t, i18n } = useTranslation()

  const TRACKING_ID = "G-NF9P8D0MLE"; // OUR_TRACKING_ID
  //TODO: Aina pageview.
  ReactGA.send({ hitType: "pageview", title: "PageView" });


  const { scrollYProgress } = useScroll()
  const scale = useTransform(scrollYProgress, [0, 0.5], [0.4, 1])

  return (
    <div className='font'>
      
      <div className='start_container'>

      <section id="home">
  <div className='background section-background'>
    <VideoBackground imageUrl={backgroundImageUrl} />
  </div>
  <img className='WelcomeLamppu' src={lamppu2} alt="Welcome Lamp" />
  <img className="WelcomeLogo" src={logo} alt="Company Logo" />
</section>

<div className="header-buttons">
            <a href="https://www.facebook.com/profile.php?id=61551928770102" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f headersome"></i>
            </a>
            <a href="https://www.instagram.com/tinkerit.fi/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram headersome"></i>
            </a>
            <a href="https://www.linkedin.com/company/96452239/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in headersome"></i>
            </a>
</div>


<div className='linkbar'>
            <a href="#services" className='heading-font'>{t('SERVICES')}</a>
            <a href="#projects" className='heading-font'>{t('PROJECTS')}</a>
            <a href="#team" className='heading-font'>{t('OURPEOPLE')}</a>
            <a href="#pricing" className='heading-font'>{t('PRICING')}</a>
            <a href="#contact" className='heading-font'>{t('CONTACTUS')}</a>
</div>

          <span className='header-text'>{t('HEADER-TEXT')}</span>
      
      
      {/* <h2 className='HomePageTitle'></h2> */}
      <div className='HomePageText'>
        <img className='keltainenlamppu' src={keltainenlamppu} alt="TinkerIt lamp" />
        <motion.div variants={boxVariant} initial="hidden" animate="visible" className='text-container'>

           <p className='Intro-text'>
           <span className='bluetext'>TinkerIT Oy</span> {t('INTRO1')} <br></br> <br></br> 
           {t('INTRO2')} <br></br> <br></br>
           {t('INTRO3')} <a href="mailto:info@tinkerit.fi" className='bluetext'>info@tinkerit.fi</a>. {t('INTRO4')}
          </p>   
          
        </motion.div>
        {/* <motion.div variants={boxVariant} initial="hidden" animate="visible" className='lottieplayercontainer'>
            <Player
              src={wateranimation}
              className="lottieplayer"
              loop
              autoplay
            />
            </motion.div> */}
      </div>
      </div>

      <section id="services">
      <Services/>
      </section>

      <section id="projects">
      <Projects />
      </section>
      

      <section id="team">
  <h2 className='TeamHeader'>{t('OURPEOPLE')}</h2>
  <motion.div style={{ scale }} className='PersonContainer'>
      <PersonCard
        id="1"
        image={require('./Images/Miro1.webp')}
        hoverImage={require('./Images/Miro2.webp')}
        alt="Miro Tammi"
        name="Miro Tammi"
        info={t('MIRO')}
        quote={t("MIRO-quote")}
        description={t('MIRO-description')}
        github="https://github.com/mbtammi"
        linkedin="https://www.linkedin.com/in/miro-tammi-701bb3205/"
        scrollPosition={5900}
        backgroundColor="#F1B805"
        smallBoxColor="#FAD205"
      />
      <PersonCard
        id="2"
        image={require('./Images/Eemil1.webp')}
        hoverImage={require('./Images/Eemil2.webp')}
        alt="Eemil Hukkanen"
        name="Eemil Hukkanen"
        info={t('EEMIL')}
        quote={t("EEMIL-quote")}
        description={t('EEMIL-description')}
        github="https://github.com/eepehukk"
        linkedin="https://www.linkedin.com/in/eemil-hukkanen-843392256/"
        scrollPosition={6200}
        backgroundColor="#F1B805"
        smallBoxColor="#FAD205"
      />
      <PersonCard
        id="3"
        image={require('./Images/Henu1.png')}
        hoverImage={require('./Images/Henu2.png')}
        alt="Henriikka Salo"
        name="Henriikka Salo"
        info={t('HENU')}
        quote={t("HENU-quote")}
        description={t('HENU-description')}
        linkedin="https://www.linkedin.com/in/henriikka-salo-2508b220a/"
        instagram="https://www.instagram.com/salohene/"
        scrollPosition={6600}
        backgroundColor="#F1B805"
        smallBoxColor="#FAD205"
      />
    <PersonCard
      id="4"
      image={require('./Images/Jesse1.webp')}
      hoverImage={require('./Images/Jesse2.webp')}
      alt="Jesse Haimi"
      name="Jesse Haimi"
      info={t('JESSE')}
      quote={t("JESSE-quote")}
      description={t('JESSE-description')}
      github="https://github.com/jkhaimi"
      linkedin="https://www.linkedin.com/in/jesse-haimi-019429256/"
      scrollPosition={6900}
      backgroundColor="#F1B805"
      smallBoxColor="#FAD205"
      />
    <PersonCard
      id="5"
      image={require('./Images/Kaitsu1.webp')}
      hoverImage={require('./Images/Kaitsu2.webp')}
      alt="Phatchanon Chuchat"
      name="Phatchanon 'Kaitsu' Chuchat"
      info={t('KAITSU')}
      quote={t("KAITSU-quote")}
      description={t('KAITSU-description')}
      github="https://github.com/pchuchat"
      linkedin="https://www.linkedin.com/in/phatchanon-chuchat-5a3660211/"
      scrollPosition={7300}
      backgroundColor="#F1B805"
      smallBoxColor="#FAD205"
      />
    <PersonCard
      id="6"
      image={require('./Images/lamppu2.png')}
      hoverImage={require('./Images/lamppukeltainen1.png')}
      alt="TinkerIT"
      name={t('NEWMEMBER')}
      info={t('NEWMEMBER-info')}
      quote={t("NEWMEMBER-quote")}
      description={t('NEWMEMBER-description')}
      scrollPosition={7700}
      backgroundColor="#0174BE"
      smallBoxColor="#FAD205"
      />
  </motion.div>
</section>

      <section id="pricing">
      <Pricing />
      </section>

      <section id="contact">
        <ContactUs />
      </section>
      
      <div>
        {<Footer/>}
      </div>
    </div>
  );
};

export default Home;