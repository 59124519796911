import React from 'react';
import './VideoBackground.css';
import { color } from 'framer-motion';

const testvar = window.innerHeight;

const VideoBackground = ({ imageUrl, text }) => {
  const backgroundStyle = {
    backgroundImage: <img href={imageUrl}></img>,
  };

  return (
    <div className="video-container" >
      <img src={imageUrl} alt="Person" className="backgroundimage" style={{ height: testvar }} />
      <div className="content">
        <h1 style={{color:'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>{text}</h1>
      </div>
    </div>
  );
};

export default VideoBackground;
