import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          'COOKIE_CONSENT_MESSAGE': "This website uses cookies to enhance the user experience.",
          "PRIVACY-STATEMENT": "Cookie Policy",
          "COOKIE_PREFERENCES":"Change cookie preferences",
          "ACCEPT":"Accept",
          "DECLINE":"Decline",
          "WELCOME":"Website for your business?",
          "HEADER-TEXT":"Websites and online stores for your company",
          "INTRO1": "is a Finnish company specializing in the development of websites and online stores. Our goal is to provide clear, functional, and visually appealing solutions for everyone. In our operations, we emphasize creating new opportunities, clear communication, and transparency. Our team is composed of skilled and dedicated professionals who will bring your vision to life and keep you informed throughout the entire project, from start to finish.",
          "INTRO2": "We continuously strive to improve our expertise and stay up-to-date with the latest technological advancements to offer you the best possible service. Our goal is to be a reliable partner who helps you achieve your business objectives and gain a competitive edge in the market.",
          "INTRO3": "If you need a new website or an update to an existing one, please feel free to contact us via the form at the bottom of the page or send a message to",
          "INTRO4": "Our professional and enthusiastic team is ready to assist you and find the right solution for your needs.",
          
          "SERVICES":"Our Services",
          "SERVICES1":"Professional and functional websites",
          "SERVICES2":"Using modern practices to create responsive and user-friendly websites that enhance your company's marketing and image.",
          "SERVICES3":"Efficient and scalable online store solutions",
          "SERVICES4":"Successful online stores that provide a seamless shopping experience.",
          "SERVICES5":"Reach your target audience online",
          "SERVICES6":"Increasing visibility and attracting potential customers through digital marketing.",
          "SERVICES7":"Experienced professionals at your use",
          "SERVICES8":"Comprehensive IT consulting and advisory services that help leverage the potential of technology and thrive in a digitalizing world.",
          "SERVICES9": "Integration between services",
          "SERVICES10": "We deliver reliable and scalable integration solutions that seamlessly connect your systems and enhance your company's efficiency.",
          "SERVICES11": "Graphic design",
          "SERVICES12": "We create impactful visual solutions that strengthen your brand and set it apart from the competition.",

          "OURPEOPLE":"The Team",
          "MIRO": "Administrative Tasks & Coding",
          "MIRO-quote": "Is it fun when we do business, or fun business?",
          "MIRO-description": "Several years of experience in building online stores and websites. I ensure that all parties are satisfied, and it doesn't hurt to have fun along the way.",
          "EEMIL": "Client Relations & Coding",
          "EEMIL-quote": "Relaxed and professional service to guarantee a solution tailored to you!",
          "EEMIL-description": "Several years of experience in founding and managing organizations, and thus working with people. It's important for me to listen to you and provide support.",
          "JESSE": "Marketing & Coding",
          "JESSE-quote": "Programming isn't just code; it's an opportunity to turn ideas into reality.",
          "JESSE-description": "Deep experience in utilizing artificial intelligence in programming and the software industry. I ensure that your wishes are realized!",
          "KAITSU": "Finance & Coding",
          "KAITSU-quote": "Combining information is the key to success—I build bridges between systems.",
          "KAITSU-description": "A wealth of projects under my belt and several years of experience in the field. I enjoy technical challenges, and even more so, solving them.",
          "HENU": "Graphic Design & Styling",
          "HENU-quote": "Problem-solving and a cohesive whole—I bring your ideas to life!",
          "HENU-description": "Several years of experience and relevant education in graphic design. I ensure that the appearance of the pages is pleasant, and I take the client's wishes into account.",
          "NEWMEMBER": "Interested in joining our team?",
          "NEWMEMBER-info": "Contact us at the bottom of the page",
          "NEWMEMBER-description": "Working at TinkerIT combines a relaxed atmosphere with ambitious goals, making us a unique workplace. Here, you can be yourself, as we value openness and a good sense of humor. At the same time, we strive together for top results and are committed to providing our clients with the best possible service.",
          "NEWMEMBER-quote": "Big things are coming",

          "PROJECTS": "Projects",
          "OUR_PROJECTS": "Our Projects",
          "PROJECT1_NAME": "Thai Market Olari",
          "PROJECT1_DESCRIPTION": "Thai groceries directly to your door",
          "PROJECT2_NAME": "Information Security Expert Heidi",
          "PROJECT2_DESCRIPTION": "Information security consultation at a fare price",
          "PROJECT3_NAME": "Airport Taxi",
          "PROJECT3_DESCRIPTION": "Reliable transport service at a fixed price",
          "PROJECT4_NAME": "Your Project",
          "PROJECT4_DESCRIPTION": "We are here to bring your vision to life together!",
          "PROJECT5_NAME": "Eristyspalvelu Kaasalainen",
          "PROJECT5_DESCRIPTION": "An insulation company specializing in HVAC",
          "PROJECT6_NAME": "Savolainen osakunta",
          "PROJECT6_DESCRIPTION": "A student organization for people from Savo and Savo minded people",

          "PROJECT_ORDERPAGE": "order website",
          "PROJECT_VISITPAGE": "visit page",

          "TECHNOLOGIES":"TECHNOLOGIES",
          "TECHNOLOGIES1":"In the development phase, we use technologies that are most optimal for implementing the product.",
          "TECHNOLOGIES2":"We constantly learn new technologies to enhance our expertise. Here are a few technologies we use.",

          "PRICING":"Pricing",
          "PRICING1": "Business Card Website",
          "PRICING1-1": "Single Page Website",
          "PRICING1-2": "Approx. 3-6 sections",
          "PRICING1-3": "Clean design",
          "PRICING1-4": "Responsiveness",
          
          "PRICING2": "Basic Website",
          "PRICING2-1": "Multi-page Website",
          "PRICING2-2": "Several personalized sections",
          "PRICING2-3": "Logical navigation",
          "PRICING2-4": "Modern appearance",
          
          "PRICING3": "E-commerce Website",
          "PRICING3-1": "Complete e-commerce solution",
          "PRICING3-2": "Modern SEO optimization",
          "PRICING3-3": "User-friendly interface",
          "PRICING3-4": "Security ensured",
          
          "PRICING4": "Comprehensive Solution",
          "PRICING4-1": "Request project-specific quote",
          "PRICING4-2": "Desired number of pages",
          "PRICING4-3": "Customized sections",
          "PRICING4-4": "Professional consultation",
          "PRICING4-5": "Increasing online visibility",
          
          "PRICING-BUTTON": "Order website",
          "PRICING-BUTTON-2": "Request offer",
          "ALV":"(Incl. VAT 25.5%)",

          
          "CONTACTUS":"Contact Us",
          "CONTACT":"Leave a contact request",
          "CONTACT1":"Would you like to learn more about our services or submit a quote request? Fill out the form, and we will get in touch with you.",
          "CONTACT2":"Or call the number",
          "CONTACT3":"+358 40 351 3954",
          "SOME": "Our social medias",
          "SOME1": "Follow us on our social media channels to stay updated!",
          "NAME":"NAME",
          "EMAIL":"EMAIL",
          "MESSAGE":"MESSAGE",
          "SEND":"SEND",
          "SENT":"SENT",
          "ERROR1": "Make sure you fill all the sections",
          "SUCCESS": "Your message was sent successfully!",

          "VISUAL-IDENTITY": "Visual Identity by Henriikka Salo. Images by ",
          "COMPANY-NAME": "TinkerIT Oy, 2024. All rights reserved",
          "BUSINESS-ID": "Business ID: 3376051-6",
          "PRIVACY-POLICY": "Privacy Policy"
        }
      },
      fi: {
        translation: {
          "COOKIE_CONSENT_MESSAGE": "Tämä verkkosivu käyttää evästeitä.",
          "PRIVACY-STATEMENT": "Evästeseloste",
          "COOKIE_PREFERENCES":"Muuta evästeasetuksia",
          "ACCEPT":"Hyväksy",
          "DECLINE":"Hylkää",
          "HEADER-TEXT":"Verkkosivut ja verkkokaupat yrityksellesi",
          "INTRO1":"on suomalainen verkkosivujen ja verkkokauppojen kehitysyritys. Haluamme mahdollistaa jokaiselle selkeät, toimivat ja silmää miellyttävät ratkaisut. Tominnassamme tärkeää on uusien mahdollisuuksien luominen, selkeä viestintä ja avoimuus. Tiimimme koostuu taitavista ja ahkerista moniosaajista, jotka toteuttavat toiveenne ja pitävät teidät kartalla projektin kulusta alusta loppuun asti.",
          "INTRO2":"Pyrimme jatkuvasti kehittämään osaamistamme ja pysymään ajan tasalla uusista teknologisista kehityksistä, tarjotaksemme teille parhaan mahdollisen palvelun. Meidän tavoitteenamme on olla luotettava kumppani, joka auttaa teitä saavuttamaan liiketoiminnaliset tavoitteenne ja kilpailuedun markkinoilla.",
          "INTRO3":"Jos teillä on tarve uusille sivuille tai tarvitsette päivitystä vanhaan, otathan rohkeasti yhteyttä sivun alalaidasta tai lähetä viesti osoitteeseen",
          "INTRO4":"Meidän ammattitaitoinen ja innokas tiimimme on valmis auttamaan teitä ja löytämään teille oikean ratkaisun.",

          "SERVICES":"Palvelumme",
          "SERVICES1":"Ammattimaiset ja yksinkertaiset verkkosivut",
          "SERVICES2":"Moderneilla käytännöillä luodut responsiiviset ja helppokäyttöiset nettisivut, jotka vahvistavat yrityksenne markkinointia ja imagoa.",
          "SERVICES3":"Tehokkaat ja skaalautuvat verkkokaupat",
          "SERVICES4":"Menestyksekkäitä verkkokauppoja, jotka tarjoavat saumattoman ostokokemuksen.",
          "SERVICES5":"Tavoita kohdeyleisösi verkossa",
          "SERVICES6":"Näkyvyyden kasvattaminen ja potentiaalisten asiakkaiden houkutteleminen digitaalisen markkinoinnin ja hakukoneoptimoinnin avulla.",
          "SERVICES7":"Kokeneet asiantuntijat käytössänne",
          "SERVICES8":"Laaja-alaista IT-osaamista ja neuvontaa, joka auttaa hyödyntämään teknologian mahdollisuuksia ja menestymään digitalisoituvassa maailmassa.",
          "SERVICES9":"Integraatio palveluiden välillä",
          "SERVICES10":"Toteutamme luotettavia ja skaalautuvia integraatioratkaisuja, jotka yhdistävät järjestelmänne saumattomasti ja parantavat yrityksenne tehokkuutta.",
          "SERVICES11":"Graafinen suunnittelu",
          "SERVICES12":"Luomme vaikuttavia visuaalisia ratkaisuja, jotka vahvistavat yrityksesi brändiä ja erottavat sen kilpailijoista.",


          "OURPEOPLE":"Meidän tiimi",
          "MIRO":"hallinnolliset tehtävät & koodaus",
          "MIRO-quote": "Hauskaa kun tehdään bisnestä, vai hauskaa bisnestä?",
          "MIRO-description": "Useamman vuoden työkokemus verkkosivujen ja -kauppojen rakentamisesta. Pidän huolen kaikkien osapuolten tyytyväisyydestä.",
          "EEMIL":"asiakkuudet & koodaus",
          "EEMIL-quote": "Rentoa ja asiantuntevaa palvelua teidän näköisen ratkaisun takaamiseksi!",
          "EEMIL-description": "Useamman vuoden kokemus järjestöjen perustamisesta ja pyörittämisestä on tuonut osaamista ihmisten kanssa toimimisessa. Minulle on tärkeää kuunnella sinua ja olla tukenasi.",
          "JESSE":"markkinointi & koodaus",
          "JESSE-quote": "Ohjelmointi ei ole vain koodia, vaan mahdollisuus muuttaa ideoita todellisuudeksi.",
          "JESSE-description": "Vankkaa työkokemusta tekoälyn hyödyntämiestä ohjelmoinnissa. Pidän huolen toiveidesi toteutumisesta.",
          "KAITSU":"talous & koodaus",
          "KAITSU-quote": "Tiedon yhdistäminen on avain menestykseen – minä rakennan siltoja järjestelmien välillä.",
          "KAITSU-description": "Suuri määrä projekteja vyön alla ja usemman vuoden kokemus alalta. Pidän teknisistä haasteista ja vielä enemmän niiden ratkaisemisesta.",
          "HENU": "graafinen suunnittelu & muotoilu",
          "HENU-quote": "Ongelmanratkaisu ja toimiva kokonaisuus - teen sun ideat näkyviksi!",
          "HENU-description": "Usean vuoden kokemus suunnittelusta ja graafisen suunnittelun opinnot. Pidän huolen sivujen ulkonäön miellyttävyydestä ja asettelusta. Otan työssäni asiakkaan toiveet ja tarpeet huomioon.",
          "NEWMEMBER": "Sinustako tiimin jäsen?",
          "NEWMEMBER-info": "Ota yhteyttä sivun alalaidasta",
          "NEWMEMBER-description":"TinkerIT -tiimissä työskentely yhdistää rennon ilmapiirin ja kunnianhimoiset tavoitteet tavalla, joka tekee meistä ainutlaatuisen työyhteisön. Meillä voit olla oma itsesi. Takaamme innostavan ja hauskan ilmapiirin työskennellä. Yhdessä pyrimme huipputuloksiin ja olemme sitoutuneita tarjoamaan asiakkaillemme parasta mahdollista palvelua.",
          "NEWMEMBER-quote": "isoi juttui tulos", 

          "PROJECTS": "Projektimme",
          "OUR_PROJECTS": "Projektimme",
          "PROJECT1_NAME": "Thai Market Olari",
          "PROJECT1_DESCRIPTION": "Thaimaalaiset ruokatarvikkeet suoraan kotiovellesi",
          "PROJECT2_NAME": "Tietoturva-asiantuntija Heidi",
          "PROJECT2_DESCRIPTION": "Tietoturvakonsultaatiota edullisesti",
          "PROJECT3_NAME": "Airport Taxi",
          "PROJECT3_DESCRIPTION": "Luotettava kuljetuspalvelu kiinteään hintaan",
          "PROJECT4_NAME": "Sinun Projekti",
          "PROJECT4_DESCRIPTION": "Olemme täällä toteuttamassa sinun visiotasi yhdessä!",
          "PROJECT5_NAME": "Eristyspalvelu Kaasalainen",
          "PROJECT5_DESCRIPTION": "Lvi-alaan keskittynyt eristysliike",
          "PROJECT6_NAME": "Savolainen osakunta",
          "PROJECT6_DESCRIPTION": "Savolaisten ja savolaismielisten opiskelijajärjestö",
          "PROJECT_VISITPAGE": "vieraile sivulla",
          "PROJECT_ORDERPAGE": "tilaa sivustot",
          
          
          "TECHNOLOGIES":"TEKNOLOGIAT",
          "TECHNOLOGIES1":"Kehittämisvaiheessa käytämme teknologioita, jotka ovat optimaalisimmat toteutettavaa tuotetta varten.",
          "TECHNOLOGIES2":"Opettelemme jatkuvasti uusia teknologioita kehittääkseemme osaamistamme. Tässä muutamia teknologioita, joita käytämme.",

          "PRICING":"Hinnasto",
          "PRICING1":"Käyntikorttisivusto",
          "PRICING1-1":"Yhden sivun sivusto",
          "PRICING1-2":"noin. 3-6 osiota",
          "PRICING1-3":"Siisti ulkoasu",
          "PRICING1-4":"Responsiivisuus",

          "PRICING2":"Perussivusto",
          "PRICING2-1":"Usean sivun sivusto",
          "PRICING2-2":"Useita persoonallisia osioita",
          "PRICING2-3":"Looginen navigointi",
          "PRICING2-4":"Moderni ulkonäkö",

          "PRICING3":"Verkkokauppasivusto",
          "PRICING3-1":"Verkkokauppa kokonaisuus",
          "PRICING3-2":"Moderni SEO-optimointi",
          "PRICING3-3":"Käyttäjäystävällinen käyttöliittymä",
          "PRICING3-4":"Turvalliset maksutapahtumat",

          "PRICING4":"Laajempi kokonaisuus",
          "PRICING4-1":"Kysy projektikohtausta tarjousta",
          "PRICING4-2":"Haluttu sivumäärä",
          "PRICING4-3":"Mukautetut osiot",
          "PRICING4-4":"Ammattimaista konsultointia",
          "PRICING4-5":"Nettinäkyvyyden kasvattaminen",

          "PRICING-BUTTON":"Tilaa sivustopaketti",
          "PRICING-BUTTON-2":"Pyydä tarjous",
          "ALV":"(sis. alv 25.5%)",

          
          "CONTACTUS":"Ota yhteyttä",
          "CONTACT":"Jätä yhteydenottopyyntö",
          "CONTACT1":"Haluatko kuulla lisää palveluistamme tai jättää tarjouspyynnön? Täytä lomake ja olemme sinuun yhteydessä.",
          "CONTACT2":"Tai soita numeroon",
          "CONTACT3":"+358 40 351 3954",
          "SOME": "Sosiaaliset mediat",
          "SOME1": "Seuraa meitä sosiaalisen median kanavissamme pysyäksesi ajan tasalla!",
          "NAME":"NIMI",
          "EMAIL":"SÄHKÖPOSTI",
          "MESSAGE":"VIESTI",
          "SEND":"LÄHETÄ",
          "SENT":"LÄHETETTY",
          "ERROR1": "Varmista että täytit kaikki kohdat",
          "SUCCESS": "Viestisi lähetettiin onnistuneesti!",

          "VISUAL-IDENTITY": "Visuaalinen ilme: Henriikka Salo. Kuvat: ",
          "COMPANY-NAME": "TinkerIT Oy, 2024. Kaikki oikeudet pidätetään",
          "BUSINESS-ID": "Y-tunnus: 3376051-6",
          "PRIVACY-POLICY": "Tietosuojakäytäntö"

        }
      },
    },
    lng: "fi", // if you're using a language detector, do not define the lng option
    fallbackLng: "fi",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;