import React, { useState } from 'react';
import './Projects.css';
import { useTranslation } from 'react-i18next';
import thaimarketolari from '../Images/thaimarketolari.png';
import lamppu from '../Images/lamppu2.png';
import keltainenlamppu from '../Images/lamppukeltainen1.png';
import taxi from '../Images/taxi.png';
import taxi2 from '../Images/taxi2.png';
import question1 from '../Images/question.png';
import question2 from '../Images/question2.png';
import savolainen from '../Images/savolainen-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import background from "../Images/projects-background.jpg";

const projectsData = [
  {
    id: 1,
    name: 'PROJECT1_NAME',
    logo: thaimarketolari,
    descriptionKey: 'PROJECT1_DESCRIPTION',
    visitpage: 'PROJECT_VISITPAGE',
    website: 'https://thaimarketolari.fi/'
  },
  {
    id: 2,
    name: 'PROJECT2_NAME',
    logo: keltainenlamppu,
    hoverLogo: lamppu,
    descriptionKey: 'PROJECT2_DESCRIPTION',
    visitpage: 'PROJECT_VISITPAGE',
    website: 'https://www.tietoturva-asiantuntija.fi/'
  },
  {
    id: 3,
    name: 'PROJECT3_NAME',
    logo: taxi,
    descriptionKey: 'PROJECT3_DESCRIPTION',
    visitpage: 'PROJECT_VISITPAGE',
    website: 'https://www.airporttaxi.fi/'
  },
  {
    id: 6,
    name: 'PROJECT6_NAME',
    logo: savolainen,
    descriptionKey: 'PROJECT6_DESCRIPTION',
    visitpage: 'PROJECT_VISITPAGE',
    website: 'https://www.savolainenosakunta.fi/'
  },
  {
    id: 5,
    name: 'PROJECT5_NAME',
    logo: keltainenlamppu,
    hoverLogo: lamppu,
    descriptionKey: 'PROJECT5_DESCRIPTION',
    visitpage: 'PROJECT_VISITPAGE',
    website: 'https://www.eristys.fi/'
  },
  {
    id: 4,
    name: 'PROJECT4_NAME',
    logo: question1,
    hoverLogo: question2,
    descriptionKey: 'PROJECT4_DESCRIPTION',
    visitpage: 'PROJECT_ORDERPAGE',
    website: '#contact'
  },
];

const Projects = () => {
  const { t } = useTranslation();
  const [hoveredCard, setHoveredCard] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
    ],
    prevArrow: (
      <button aria-label="Previous" type="button" className="slick-prev">
        <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'white', fontSize: '32px' }} />
      </button>
    ),
    nextArrow: (
      <button aria-label="Next" type="button" className="slick-next">
        <FontAwesomeIcon icon={faArrowRight} style={{ color: 'white', fontSize: '32px' }} />
      </button>
    ),
  };

  return (
    <section className='projects-section' style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="projects-container">
        <h2 className='projects-header'>{t('OUR_PROJECTS')}</h2>
        <Slider {...settings}>
          {projectsData.map(project => (
            <div
              className={`project-card ${getCardColor(project.id)}`}
              key={project.id}
              onMouseEnter={() => setHoveredCard(project.id)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <a href={project.website} className="project-link">
              <img
                src={hoveredCard === project.id && project.hoverLogo ? project.hoverLogo : project.logo}
                alt={project.name}
                className={`project-logo ${project.id === 1 ? 'thaimarketolari-image' : ''} ${project.id === 2 ? 'keltainenlamppu-image' : ''} ${project.id === 3 ? 'taxi-image' : ''} ${project.id === 4 ? 'gale-image' : ''} ${project.id === 5 ? 'lamppu-image' : ''} ${project.id === 6 ? 'savolainen-image' : ''}`}
              />
                <div className="project-description">
                  <h3 className='project-title'>{t(project.name)}</h3>
                  <br />
                  <p className="project-description-text">{t(project.descriptionKey)}</p>
                  <div className="visit-page">
                    <p>{t(project.visitpage)}</p>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

const getCardColor = (projectId) => {
  switch (projectId) {
    case 1:
      return 'thaimarketolari-card';
    case 2:
      return 'heidi-card';
    case 3:
      return 'airporttaxi-card';
    case 4:
      return 'gale-card';
    case 5:
      return 'taxi2-card';
    case 6:
      return 'gale2-card';
    default:
      return '';
  }
};

export default Projects;
