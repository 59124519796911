import React, { useEffect } from 'react';
import './App.css';
import Home from './Home';
import Header from './Header';
import CookieConsent from './components/CookieConsent';  
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const App = () => {
  const { t, i18n } = useTranslation();
  const TRACKING_ID = "G-NF9P8D0MLE"; 
  ReactGA.initialize(TRACKING_ID);

  // Dynamically load Google Fonts
  useEffect(() => {
    // Load Google Fonts for IBM Plex Mono, IBM Plex Sans, and Oswald
    const googleFontLink = document.createElement("link");
    googleFontLink.href = "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&family=IBM+Plex+Sans:wght@400;500;600;700&family=Oswald:wght@400;500;600&display=swap";
    googleFontLink.rel = "stylesheet";
    document.head.appendChild(googleFontLink);
    
    // Clean up links when component unmounts
    return () => {
      document.head.removeChild(googleFontLink);
    };
  }, []);

  return (
    <div>
      <Header />
      <CookieConsent />
      <Home />
    </div>
  );
};

export default App;
