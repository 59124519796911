import React from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import logo from './Images/header-logo.png';
import { showCookieConsentBanner } from './components/CookieConsent';


export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave">
        <path fill="#2999E4" fill-opacity="1" d="M0,160L48,138.7C96,117,192,75,288,101.3C384,128,480,224,576,240C672,256,768,192,864,165.3C960,139,1056,149,1152,170.7C1248,192,1344,224,1392,240L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      <div className="footer-content">
        <div className="footer-left">
          <img
            className='footer-logo'
            alt="logo"
            src={logo}
            width="300px"
          />
          <span className="footer-text">
            &copy; {t('VISUAL-IDENTITY')} <a href='https://www.linkedin.com/in/juuso-v-703a26228/' style={{color: "white", textDecoration: "none"}}>Juuso Vuorela</a>. {t('COMPANY-NAME')}
            . {t('BUSINESS-ID')} | 
            <a style={{textDecoration: "none", color: "white"}} href="./Tietosuojaseloste.pdf" target="_blank" rel="noopener noreferrer">
              {t('PRIVACY-POLICY')} | 
            </a>
            <a 
              style={{textDecoration: "none", color: "white", cursor: "pointer"}} 
              onClick={showCookieConsentBanner}
            >
              {t('COOKIE_PREFERENCES')}
            </a>
          </span>
        </div>
      </div>
      <span className="footer-text-mobile">
        &copy; {t('VISUAL-IDENTITY')} <a href='https://www.linkedin.com/in/juuso-v-703a26228/' style={{color: "white", textDecoration: "none"}}>Juuso Vuorela</a>. {t('COMPANY-NAME')} <br></br> {t('BUSINESS-ID')} 
      </span>
      <div className='footer-bottomrow-mobile'>
        <p className='footer-ytunnus-mobile'>
 
          <a 
            style={{ textDecoration: "none", color: "white", paddingLeft: "5px" }} 
            href="/Tietosuojaseloste.pdf" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {t('PRIVACY-POLICY')}
          </a>
          <a 
            style={{textDecoration: "none", color: "white", cursor: "pointer"}} 
            onClick={showCookieConsentBanner}
          >
            {t('COOKIE_PREFERENCES')}
          </a>
        </p>
      </div>
    </footer>
  );
};